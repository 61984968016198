(function () {
	var performAjaxRequest = function(url, options, callback)
	{
		options._token = _csrf;
		$.ajax({
			url: url,
			data: options,
			type: "POST",
			dataType: "html",
			success: function (data) {
				callback(data);
			},
			error: function (xhr, status) {
				alertAjaxError();
			}
		});
	};

	var getWordpressFeed = function () {
		var options = {};
		var url = "/ajax/getwordpressfeed";
		performAjaxRequest(url, options, setWordpressFeed);
	};

	var setWordpressFeed = function (data) {
		$('#nowhowContainer').html(data);
	};

	/*
	 |--------------------------------------------------------------------------
	 | RETRIVE WORDPRESS FEED
	 |--------------------------------------------------------------------------
	 */
	//getWordpressFeed();

	/*
	|--------------------------------------------------------------------------
	| COUNTERUP
	|--------------------------------------------------------------------------

	var counted = false;

	$(window).scroll(function() {
		var element = $('#Numbers');
		var top_of_element = element.offset().top;
		var bottom_of_element = element.offset().top + element.outerHeight();
		var bottom_of_screen = $(window).scrollTop() + $(window).height();

		if((bottom_of_screen > top_of_element) && (bottom_of_screen < bottom_of_element)) {
			if ( ! counted ) {
				countItUp()
			}
		}
	});

	var countItUp = function() {
		var options = {
			useEasing : true,
			useGrouping : true,
			separator : '',
			decimal : '.',
			prefix : '',
			suffix : ''
		};
		var counter_year = new CountUp("counter_year", 0, 2008, 0, 2.5, options);
		var counter_people = new CountUp("counter_people", 0, 20000, 0, 2.5, options);
		var counter_hours = new CountUp("counter_hours", 0, 11000, 0, 2.5, options);
		var counter_courses = new CountUp("counter_courses", 0, 90, 0, 2.5, options);
		counter_year.start();
		counter_people.start();
		counter_hours.start();
		counter_courses.start();
		counted = true;
	};

	var element = $('#Numbers');
	var top_of_element = element.offset().top;
	var bottom_of_element = element.offset().top + element.outerHeight();
	var bottom_of_screen = $(window).scrollTop() + $(window).height();
	console.log(bottom_of_screen);
	
	if((bottom_of_screen > top_of_element) && (bottom_of_screen < bottom_of_element)) {
		if ( ! counted ) {
			countItUp()
		}
	}
*/
		/*
	|--------------------------------------------------------------------------
	| COVERVID
	|--------------------------------------------------------------------------
	*/

	$('.covervid-video').coverVid(1920, 1080);

})();